import { mapHelper } from 'common/utils'

// 是否已读
const noticeType = [
  {
    text: '系统通知',
    value: 1
  },
  {
    text: '订单通知',
    value: 2
  },
  {
    text: '寄存通知',
    value: 3
  },
  {
    text: '快递通知',
    value: 4
  },
  {
    text: '党建通知',
    value: 11
  }
]

const {
  map: noticeTypeMap,
  setOps: noticeTypeOps
} = mapHelper.setMap(noticeType)

// 是否已读
const isRead = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: isReadMap,
  setOps: isReadOps
} = mapHelper.setMap(isRead)

export {
  noticeTypeMap,
  noticeTypeOps,
  isReadMap,
  isReadOps
}
