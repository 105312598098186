<template>
  <div class="systemNotificationList-wrapper">
    <list ref="list"
          exportMethod="delay"
          :exportPermission="true"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers"
          :hasOperateColumn="false">
      <template #searchSlot>
        <v-datepicker label="创建时间段"
                      type="rangedatetimer"
                      :startTime.sync="searchParams.startTime"
                      :endTime.sync="searchParams.endTime"></v-datepicker>
        <v-input label="标题"
                 v-model="searchParams.noticeTitle"></v-input>
        <v-select label="通知类型"
                  v-model="searchParams.noticeType"
                  :options="noticeTypeOps"></v-select>
        <v-select label="是否已读"
                  v-model="searchParams.isRead"
                  :options="isReadOps"></v-select>
      </template>
      <el-dialog :title="noticeTitle"
                 width="450px"
                 :visible.sync="dialogVisible">
        <div class="notice-content"
             v-html="noticeContent"></div>
      </el-dialog>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL } from './api'
import { noticeTypeOps, isReadOps } from './map'
import moment from 'moment'
import { createAlinkVNode } from 'common/vdom'

export default {
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      noticeTypeOps: noticeTypeOps(1),
      isReadOps: isReadOps(1),
      searchParams: {
        noticeTitle: '',
        noticeType: undefined,
        isRead: undefined,
        startTime: moment().subtract(7, 'd').format('YYYY-MM-DD HH:mm'),
        endTime: moment().format('YYYY-MM-DD HH:mm')
      },
      noticeTitle: '',
      noticeContent: '',
      dialogVisible: false,
      headers: [
        {
          prop: 'noticeTitleH5',
          label: '通知标题',
          formatter: (row, prop) => {
            return createAlinkVNode(this, row, prop, {
              text: row.noticeTitle,
              cb: this.lookDetail
            })
          }
        },
        {
          prop: 'noticeType',
          label: '通知类型'
        },
        {
          prop: 'receiveName',
          label: '发布对象'
        },
        {
          prop: 'isRead',
          label: '是否已读'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    lookDetail (row) {
      this.noticeTitle = row.noticeTitle
      this.noticeContent = row.noticeContent
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.systemNotificationList-wrapper {
  .notice-content {
    height: 250px;
    margin-top: -20px;
  }
}
</style>
